import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// imports
import { useTranslation } from "react-i18next";
import { Form, FloatingLabel, Row, Col, Button } from "react-bootstrap";

// Import the API service
import { postData } from "../../Services/apiService";

// import the components
import { useAuth } from "../../Auth/AuthProvider";
import { useAlert } from "../../Helpers/Alert/AlertContext";

// Import the CSS
import "./login.css";

export default function Login() {
    document.documentElement.setAttribute("data-bs-theme", "light");

    // Hook for the alert
    const { addAlert } = useAlert();

    // Hook for the navigation
    const navigate = useNavigate();

    // Hook for translation
    const { t, i18n } = useTranslation();
    useEffect(() => {
        const auth = JSON.parse(localStorage.getItem("auth"));
        if (auth) {
            navigate("/dashboard");
        }
        i18n.changeLanguage(localStorage.getItem("language") || "en");
    }, [i18n, navigate]);

    // Hook for the authentication
    const { login } = useAuth();

    //
    const [user, setUser] = useState({
        email: "",
        password: "",
    });

    // Valida os campos obrigatorios
    const [errors, setErrors] = useState({});
    const validateForm = () => {
        const newErrors = {};
        if (!user.email) newErrors.email = t("login.error.email");
        if (!isValidEmail(user.email)) newErrors.email = t("login.error.email");
        if (!user.password) newErrors.password = t("login.error.email");
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Function to handle the form submission
    const handleSubmit = async () => {
        if (validateForm()) {
            // Call the API to submit the login
            const submitLogin = postData("/login", {
                email: user.email,
                password: user.password,
            });
            submitLogin.then((response) => {
                // Check the response status
                if (response.status === 200) {
                    // Save the token
                    login(response.body.data);
                    // Redirect to the home page
                    navigate("/dashboard");
                    return;
                }
                // Show the error message
                if (response.status === 401) {
                    addAlert(
                        "danger",
                        t("login.title"),
                        t("login.error.invalidPassword")
                    );
                    return;
                }
                if (response.status === 404) {
                    addAlert(
                        "danger",
                        t("login.title"),
                        t("login.error.userNotFound")
                    );
                }
            });
        }
    };

    // Return the component
    return (
        <Row className="login">
            <Col md={3} className="login-container">
                <h2>{t("login.title")}</h2>

                <Form>
                    <FloatingLabel
                        controlId="inputLoginEmail"
                        label={t("login.email")}
                        className="mb-3"
                    >
                        <Form.Control
                            type="email"
                            placeholder="name@example.com"
                            onChange={(e) =>
                                setUser({ ...user, email: e.target.value })
                            }
                            isInvalid={!!errors.email}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="imputLoginPassword"
                        label={t("login.password")}
                        className="mb-3"
                    >
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            onChange={(e) => {
                                setUser({ ...user, password: e.target.value });
                            }}
                            isInvalid={!!errors.password}
                        />
                    </FloatingLabel>

                    <Form.Text className="text-muted">
                        <a href="/forgot-password">{t("login.forgot")}</a>
                        <br />
                    </Form.Text>

                    <Button variant="primary" onClick={handleSubmit}>
                        {t("login.button")}
                    </Button>
                </Form>
            </Col>
        </Row>
    );
}

function isValidEmail(email) {
    // Expressão regular para validar email
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
}
