//const API_BASE_URL = "http://63.142.253.221:8000"; 
const API_BASE_URL = "http://localhost:8000";

const fetchAPI = async (endpoint, options = {}) => {
    // Monta a URL completa
    const url = `${API_BASE_URL}${endpoint}`;
    const defaultHeaders = {
        "Content-Type": "application/json",
        "Application-Token": "7683c1e3-2eeb-4a0b-b06b-42b4aae13788",
    };

    // Adiciona o token de autenticação, se existir
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
        defaultHeaders["Authentication-Token"] = auth.token;
    }

    // Combina as opções padrão com as opções recebidas
    const config = {
        ...options,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    };

    return new Promise(async (resolve, reject) => {
        try {
            // Faz a requisição
            const response = await fetch(url, config);
            const body = await response.json();
            resolve({
                status: response.status,
                body: body,
            });
        } catch (error) {
            reject({
                status: error.status || 500,
                body: error,
            });
        }
    });
};

export const getData = async (endpoint) => {
    try {
        return await fetchAPI(endpoint, {
            method: "GET",
        });
    } catch (error) {
        return error;
    }
};

export const postData = async (endpoint, data) => {
    try {
        return await fetchAPI(endpoint, {
            method: "POST",
            body: JSON.stringify(data),
        });
    } catch (error) {
        return error;
    }
};

export const putData = async (endpoint, id = null, data) => {
    try {
        let url = ``
        if (id) {
            url = `${endpoint}/${id}`
        } else {
            url = `${endpoint}`
        }

        return await fetchAPI(url, {
            method: "PUT",
            body: JSON.stringify(data),
        });
    } catch (error) {
        return error;
    }
};

export const deleteData = async (endpoint, id) => {
    try {
        return await fetchAPI(`${endpoint}/${id}`, {
            method: "DELETE",
        });
    } catch (error) {
        return error;
    }
};
