import React, { useEffect, useState } from "react";

import { getData, putData } from "../../../../Services/apiService";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../Helpers/Alert/AlertContext";
import Loading from "../../../Layout/Components/Loading";
import LicenseMenu from "./Components/LicenseMenu";
import FixedTable from "../../../Layout/Components/Table/FixedTable";
import {
    PencilSquare,
    Ban,
    Check2Circle,
    BookmarkStar,
} from "react-bootstrap-icons";
import LicenseForm from "./Components/LicenseForm";
import {
    Modal,
    Button,
    Container,
    Row,
    Col,
    FloatingLabel,
    Form,
} from "react-bootstrap";

export default function Licenses({ idClient, contracts }) {
    const { t } = useTranslation(); // Translation hook
    const { addAlert } = useAlert(); // Alert hook
    const [loading, setLoading] = useState(true); // Loading hook
    const [reload, setReload] = useState(0); // Reload hook

    // List the licenses
    const [licenses, setLicenses] = useState([]);
    const [searsh, setSearsh] = useState("");
    const handleSearsh = async (value) => {
        setSearsh(value);
    };
    const cleanSearsh = async () => {
        setSearsh("");
    };

    const listLiceses = async () => {
        // Get the query string from the local storage
        let queryString = JSON.parse(localStorage.getItem("licenses"));
        if (!queryString) {
            queryString = {
                page: 1,
                limit: 10,
                totalItems: 0,
                order: [{ field: "id", direction: "asc" }],
            };
            localStorage.setItem("licenses", JSON.stringify(queryString));
        }

        // Get the licenses for the client
        let uri = "";
        if (idClient) {
            uri += `/license/client/${idClient}`;
        } else {
            uri += "/license";
        }

        uri += `?page=${queryString.page}&limit=${queryString.limit}`;

        // Adicionar ordenações à URI
        const orderString = encodeURIComponent(
            JSON.stringify(queryString.order)
        );
        uri += `&order=${orderString}`;

        // Adicionar filtros à URI
        if (searsh !== "") {
            uri += `&where=${searsh}`;
        }

        // Await the response
        const getLicenses = getData(uri);
        getLicenses.then((response) => {
            if (response.status === 200) {
                setLicenses(response.body.data);
                queryString.lastPage = response.body.total_pages;
                queryString.totalItems = response.body.total_items;
                localStorage.setItem("licenses", JSON.stringify(queryString));
                setLoading(false);
                return;
            } else {
                addAlert("danger", t("license.alert.error"), response.body);
            }
        });
    };

    // Edit license
    const [showEditLicense, setShowEditLicense] = useState(false);
    const [license, setLicense] = useState({});
    const editLicense = (data) => {
        setLicense(data);
        setShowEditLicense(true);
    };
    const handleCloseEditLicense = () => {
        setShowEditLicense(false);
        setLicense({});
    };

    // Column definitions
    const tableColumns = [
        { field: "id", label: "#", type: "number" },
        {
            field: "client.name",
            label: t("licenses.table.client"),
            type: "string",
        },
        {
            field: "contract.description",
            label: t("licenses.table.contract"),
            type: "string",
        },
        {
            field: "application.name",
            label: t("licenses.table.application"),
            type: "string",
        },
        { field: "serial", label: t("licenses.table.serial"), type: "string" },
        {
            field: "status",
            label: t("licenses.table.status"),
            type: "string",
            style: {
                color: (row) => {
                    return row.status === "ACTIVE" ? "green" : "red";
                },
                fontWeight: "bold",
                textAlign: "center",
            },
        },
        {
            field: "last_access",
            label: t("licenses.table.last_access"),
            type: "date-time",
        },
        {
            field: "expires_at",
            label: t("licenses.table.expires_at"),
            type: "date",
        },
        {
            field: "created_at",
            label: t("licenses.table.create_at"),
            type: "date-time",
        },
        {
            field: "update_user.name",
            label: t("licenses.table.update_user"),
            type: "string",
        },
        {
            field: "update_at",
            label: t("licenses.table.update_at"),
            type: "date-time",
        },
        {
            field: "actions",
            label: t("licenses.table.actions"),
            type: "actions",
            actions: [{ icon: <PencilSquare />, handle: editLicense }],
        },
    ];

    // Menu definitions
    const tableMenu = [
        {
            label: t("licenses.table.menu.edit"),
            icon: <BookmarkStar />,
            handle: editLicense,
        },
        {
            label: t("licenses.table.menu.active"),
            icon: <Check2Circle />,
            handle: (data) => {
                handleActive(data);
            },
            disabled: (data) => {
                return data.status === "ACTIVE" ? true : false;
            },
        },
        {
            label: t("licenses.table.menu.block"),
            icon: <Ban />,
            handle: (data) => {
                console.log(data);
            },
        },
    ];

    // Active license
    const [showSelectContract, setShowSelectContract] = useState(false);
    const [licenceActivate, setLicenceActivate] = useState({});
    const handleActive = (licence) => {
        if (contracts.length === 1) {
            licence.contract.id = contracts[0].id;
        }

        setLicenceActivate(licence);
        setShowSelectContract(true);
    };
    const handleSelectContractClose = () => {
        setShowSelectContract(false);
    };
    const handleSendActivation = () => {
        
        const activeLicense = putData(`/license/${licenceActivate.id}/active`, null, licenceActivate);
        activeLicense.then((response) => {
            if (response.status === 200) {
                addAlert(
                    "success",
                    t("license.alert.success"),
                    t("license.alert.active")
                );
                setShowSelectContract(false);
                setReload(reload + 1);
            } else {
                handleSelectContractClose();
                addAlert("danger", t("license.alert.error"), response.body);
            }
        });
    };

    useEffect(() => {
        const list = async () => {
            await listLiceses();
        };

        list();

        // eslint-disable-next-line
    }, [reload, searsh]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <LicenseForm
                        show={showEditLicense}
                        handleClose={handleCloseEditLicense}
                        license={license}
                        setReload={() => setReload(reload + 1)}
                    />
                    <LicenseMenu searsh={handleSearsh} clean={cleanSearsh} />
                    <FixedTable
                        table="licenses"
                        columns={tableColumns}
                        data={licenses}
                        menu={tableMenu}
                        reload={() => setReload(reload + 1)}
                    />

                    <Modal
                        show={showSelectContract}
                        size="lg"
                        onHide={handleSelectContractClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t("license.contract.title")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col>
                                        <FloatingLabel
                                            controlId="LicenseSelectContract"
                                            label={t(
                                                "licenses.contract.select"
                                            )}
                                        >
                                            <Form.Select
                                                aria-label="LicenseSelectContract"
                                                value={licenceActivate && licenceActivate.contract && licenceActivate.contract.id}
                                                onChange={(e) =>
                                                    setLicenceActivate({
                                                        ...licenceActivate,
                                                        contract:{
                                                            id: e.target.value,
                                                        }
                                                    })
                                                }
                                                disabled={contracts.length === 1}
                                            >
                                                {contracts.map((contract) => (
                                                    <option
                                                        key={contract.id}
                                                        value={contract.id}
                                                    >
                                                        {contract.description}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="danger"
                                onClick={handleSelectContractClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleSendActivation}
                            >
                                Confirmar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )}
        </>
    );
}
