import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Layout from "../Layout/Layout";
import Loading from "../Layout/Components/Loading";
import ClientUser from "./ClientUser";
import LiceseClient from "./Components/Licenses/Licenses";
import ClientMenuCad from "./Components/MenuClients/ClientMenuCad";
import ClientForm from "./Components/Form/ClientForm";
import ClientContract from "./Components/Contracts/Contracts";
import { TabPane, Tabs } from "react-bootstrap";
import { getData } from "../../Services/apiService";
import { useAlert } from "../../Helpers/Alert/AlertContext";

export default function ClientCad() {
    const [tabSelected, setTabSelected] = useState("tabClientForm"); // Set the selected tab
    const { t } = useTranslation(); // Hook to use the translation functions
    const [contracts, setContracts] = useState([]); // List the contracts
    const { id } = useParams(); // Get the client ID
    const [loaging, setLoading] = useState(false); // Set the loading
    const [client, setClient] = useState(); // Set the client data
    const { addAlert } = useAlert(); // Hook to use the alert function

    // Set the title of the page
    document.title = t(
        id ? "client.title-page.edit" : "client.title-page.create"
    );

    const getClient = () => {
        // Get the client data
        const getClient = getData(`/client/${id}`);
        getClient.then((response) => {
            if (response.status === 200) {
                setClient(response.body.data);
                setLoading(false);
                return;
            }

            addAlert({
                type: "danger",
                title: t("client.alert.title.error"),
                message: t("client.alert.message.error"),
            });
        });
    };

    useEffect(() => {
        if (id) {
            setLoading(true);
            getClient();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Layout>
            <div className="client-body">
                <Tabs
                    defaultActiveKey="tabClientForm"
                    id="tab-client"
                    activeKey={tabSelected}
                    onSelect={(k) => setTabSelected(k)}
                >
                    <TabPane
                        eventKey="tabClientForm"
                        title={t("client.form.tab.form")}
                    >
                        <ClientMenuCad />
                        {loaging ? <Loading /> : <ClientForm data={client} />}
                    </TabPane>
                    <TabPane
                        eventKey="tabClientContracts"
                        title={t("contract.tab.title")}
                    >
                        <ClientContract idClient={id} contract={setContracts} />
                    </TabPane>
                    <TabPane
                        eventKey="tabClientLicenses"
                        title={t("licenses.tab.title")}
                    >
                        <LiceseClient idClient={id} contracts={contracts} />
                    </TabPane>
                    <TabPane
                        eventKey="tabClientUsers"
                        title={t("client.form.tab.user")}
                    >
                        <ClientUser idClient={id} />
                    </TabPane>
                </Tabs>
            </div>
        </Layout>
    );
}
